/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ColumnFilter,
    GeneralStatus,
    PracticeContactsDto,
    PracticeContactsDtoListResponseModel,
    PracticeContactsDtoModelResponse,
    SortType,
} from "./data-contracts";
import {ContentType, HttpClient, RequestParams} from "./http-client";

export class PracticeContacts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags PracticeContacts
     * @name PostPracticeContacts
     * @request POST:/PracticeContacts/Add
     */
    postPost = (data: PracticeContactsDto, params: RequestParams = {}) => this.postPracticeContacts(data, params)
    /**
     * No description
     *
     * @tags PracticeContacts
     * @name PostPracticeContacts
     * @request POST:/PracticeContacts/Add
     */
    postPracticeContacts = (data: PracticeContactsDto, params: RequestParams = {}) =>
        this.request<PracticeContactsDtoModelResponse, any>({
            path: `/PracticeContacts/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeContacts
     * @name LoadList
     * @request GET:/PracticeContacts/Load
     */
    loadList = (
        query?: {
            Take?: number;
            Skip?: number;
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            PracticeId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<PracticeContactsDtoListResponseModel, any>({
            path: `/PracticeContacts/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeContacts
     * @name GetByIdList
     * @request GET:/PracticeContacts/GetById
     */
    getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<PracticeContactsDtoModelResponse, any>({
            path: `/PracticeContacts/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeContacts
     * @name DeleteDelete
     * @request DELETE:/PracticeContacts/Delete
     */
    deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<PracticeContactsDtoModelResponse, any>({
            path: `/PracticeContacts/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeContacts
     * @name UpdateCreate
     * @request POST:/PracticeContacts/Update
     */
    updateCreate = (data: PracticeContactsDto, params: RequestParams = {}) =>
        this.request<PracticeContactsDtoModelResponse, any>({
            path: `/PracticeContacts/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
